.social-nav-sticky {
    display: none;
    outline: none;
    transition: opacity $anim-duration-default ease-in-out,
        visibility $anim-duration-default ease-in-out;

    @include for-desktop-up {
        position: fixed;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
        left: 0;
        display: grid;
        z-index: 2;
        grid-template-columns:
            minmax($container-gutter-landscape, 1fr) minmax(0, $container-width-medium)
            minmax($container-gutter-landscape, 1fr);
    }

    .social-nav {
        @include for-desktop-up {
            pointer-events: all;
            grid-column: 3 / span 1;
            grid-row: 1;

            justify-self: center;
        }

        @media (min-width: 1864px) {
            justify-self: flex-end;
            padding-right: 4rem;
        }

        display: flex;
        flex-direction: column;

        a {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 4rem;
            height: 4rem;
            background-color: rgba(white, 0.6);
            transition: background-color $anim-duration-button ease-in-out;

            &:hover {
                background-color: white;
            }
            + a {
                margin-top: 1.6rem;
            }
        }
    }

    &--hidden {
        opacity: 0;
        visibility: hidden;
    }
}
