.footer {
    &-content {
        background-color: $color-primary-4;

        padding-top: $block-padding-mobile;
        padding-bottom: $block-padding-mobile;

        @include for-tablet-landscape-up {
            padding-top: $block-padding-landscape;
            padding-bottom: $block-padding-landscape;
        }

        @include for-desktop-up {
            padding-top: $block-padding-desktop;
            padding-bottom: $block-padding-desktop;
        }

        & {
            line-height: 1.28;
            font-size: 1.4rem;
            color: white;

            a {
                text-decoration-color: transparent;
                text-underline-position: under;

                &:hover {
                    text-decoration-color: currentColor;
                }
            }
        }

        .container {
            text-align: center;

            @include for-tablet-landscape-up {
                text-align: left;
                display: grid;
                grid-template: repeat(2, auto) / auto auto auto auto auto 1fr;
                gap: 2rem 8%;
            }
        }

        .logo {
            display: inline-block;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 2.4rem;

            @include for-tablet-landscape-up {
                margin: 0;
                grid-row: 1 / span 1;
                grid-column: 1 / span 5;
            }
        }

        .information {
            margin-bottom: 3.6rem;
            @include for-tablet-landscape-up {
                margin-bottom: 0;
                grid-row: 2 / span 1;
                grid-column: 1 / span 1;
            }

            &__title {
                @extend %footer-title;
            }

            &__content {
                line-height: 1.95;

                .small {
                    // font-size: 1.2rem;
                }
            }
        }

        .offices {
            margin-bottom: 3.6rem;
            @include for-tablet-landscape-up {
                margin-bottom: 0;
                grid-row: 2 / span 1;
                grid-column: 2 / span 1;
            }
            &__title {
                @extend %footer-title;
            }

            &__content {
                line-height: 1.95;
                a {
                    display: block;
                }
                p {
                    margin-bottom: 10px;
                }
            }
        }

        .expertises-nav {
            margin-bottom: 3.6rem;
            @include for-tablet-landscape-up {
                margin-bottom: 0;
                grid-row: 2 / span 1;
                grid-column: 3 / span 1;
            }
            &__title {
                @extend %footer-title;
            }

            &__content {
                @include for-desktop-up {
                    // column-count: 2;
                    // column-gap: 5.6rem;
                }
                line-height: 1.95;

                a {
                    display: block;
                    line-height: 1.4;
                    margin-bottom: 8px;
                }
            }
        }

        .sectors-nav {
            margin-bottom: 3.6rem;
            @include for-tablet-landscape-up {
                margin-bottom: 0;
                grid-row: 2 / span 1;
                grid-column: 4 / span 1;
            }

            &__title {
                @extend %footer-title;
            }

            &__content {
                line-height: 1.95;

                a {
                    display: block;
                    line-height: 1.4;
                    margin-bottom: 8px;
                }
            }
        }

        .general-nav {
            margin-bottom: 3.6rem;
            @include for-tablet-landscape-up {
                margin-bottom: 0;
                grid-row: 2 / span 1;
                grid-column: 5 / span 1;
            }

            a {
                @extend %footer-title;
            }
        }

        .social-nav {
            display: flex;
            flex-direction: row;
            justify-content: center;

            @include for-tablet-landscape-up {
                flex-direction: column;
                grid-row: 2 / span 1;
                grid-column: 6 / span 1;
                justify-self: flex-end;
                justify-content: flex-start;
            }

            a {
                display: flex;
                align-items: center;
                justify-content: center;

                width: 4rem;
                height: 4rem;
                background-color: rgba(white, 0.6);
                transition: background-color $anim-duration-button ease-in-out;

                &:hover {
                    background-color: white;
                }
                + a {
                    margin-left: 1.6rem;
                    @include for-tablet-landscape-up {
                        margin-left: 0;
                        margin-top: 1.6rem;
                    }
                }
            }
        }
    }

    &-legal {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;

        .container {
            text-align: center;
            @include for-tablet-landscape-up {
                display: flex;
                align-items: center;
                justify-content: space-between;
                text-align: left;
            }
        }

        .legal-links {
            @include for-tablet-landscape-up {
                display: flex;
                text-align: left;
            }

            span,
            a {
                display: block;
                font-size: 1.4rem;
                font-weight: $font-weight-regular;
                line-height: 1.28;
                color: $color-light-gray;

                + a,
                + span {
                    margin-top: 1.2rem;

                    @include for-tablet-landscape-up {
                        margin-top: 0;
                        margin-left: 3.2rem;
                    }
                }
                &:hover {
                    color: $color-primary-4;
                }
            }

            a {
                text-underline-position: under;
                text-decoration-color: transparent;
                &:hover {
                    text-decoration-color: currentColor;
                }
            }
        }

        .keplerstein {
            display: inline-flex;
            text-decoration: none;
            user-select: none;
            margin-top: 1.8rem;

            @include for-tablet-landscape-up {
                margin-top: 0;
            }

            svg {
                display: inline-block;
                transition: fill $anim-duration-default;
            }

            &:hover {
                svg {
                    fill: #005ec5;
                }
            }
        }
    }
}
