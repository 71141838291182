.header {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    display: block;
    // background-color: white;
    transition: transform $anim-duration-default ease-in-out,
        background-color $anim-duration-default ease-in-out;

    &--hidden {
        @include for-desktop-up {
            transform: translateY(-100%);
        }
    }

    &--filled {
        @include for-desktop-up {
            background-color: white;
        }
    }

    @include for-desktop-up {
        display: grid;
        grid-template-columns:
            minmax($container-gutter-landscape, 1fr) minmax(0, $container-width-medium)
            minmax($container-gutter-landscape, 1fr);
    }

    .wrapper {
        grid-column: 2 / span 1;
        grid-row: 1;
        background-color: rgba(white, 0.6);
        height: $header-height-mobile;
        padding-left: $container-gutter-mobile;
        padding-right: $container-gutter-mobile;

        @include for-desktop-up {
            padding-left: 4rem;
            padding-right: 0;
            height: $header-height-desktop;
        }

        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo {
            display: inline-block;
            flex-shrink: 0;
            width: 14rem;

            @include for-desktop-up {
                width: auto;
            }
        }

        .nav-wrapper {
            position: fixed;
            top: $header-height-mobile;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: white;
            border-top: 1px solid $color-primary;
            padding: 2.4rem;
            overflow-y: auto;
            opacity: 0;
            visibility: hidden;
            transition: opacity $anim-duration-default ease-in-out,
                visibility $anim-duration-default ease-in-out;

            body.nav-open & {
                opacity: 1;
                visibility: visible;
            }

            @include for-desktop-up {
                position: static;
                top: auto;
                left: auto;
                right: auto;
                background-color: transparent;
                border-top: none;
                padding: 0;
                overflow: visible;
                opacity: 1;
                visibility: visible;
                transition: none;
            }

            .main-nav {
                &__list {
                    text-align: center;
                    @include for-desktop-up {
                        text-align: left;
                        display: flex;
                        align-items: center;
                    }
                }

                &__item {
                    @include for-desktop-up {
                        margin-left: 4rem;
                    }

                    a {
                        display: block;
                        color: $color-black;
                        font-size: 1.4rem;
                        line-height: 1.285;
                        // letter-spacing: 0.2;
                        text-transform: uppercase;
                        text-underline-position: under;
                        text-decoration-color: transparent;
                        padding: 1.2rem 1rem;

                        @include for-desktop-up {
                            padding: 0;
                        }
                    }

                    &--client-zone {
                        a {
                            width: 100%;

                            @include for-desktop-up {
                                width: $header-height-desktop;
                                height: $header-height-desktop;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                background-color: $color-primary;
                                transition: background-color $anim-duration-button ease-in-out;

                                &:hover {
                                    background-color: $color-secondary;
                                }
                            }

                            span {
                                @include for-desktop-up {
                                    display: none;
                                }
                            }

                            svg,
                            img {
                                display: none;

                                @include for-desktop-up {
                                    width: 2.8rem;
                                    height: 1.8rem;
                                    display: inline-block;
                                }
                            }
                        }
                    }

                    &--active > a,
                    > a:hover {
                        text-decoration-color: currentColor;
                    }
                }
            }
        }

        .hamburger {
            position: relative;
            display: block;
            width: 3.2rem;
            height: 1.8rem;
            margin-left: 2.4rem;
            cursor: pointer;
            transform: rotate(0deg);
            transition: 0.2s ease-in-out;
            z-index: 3;
            align-self: center;

            &:focus {
                outline: none;
            }

            @include for-desktop-up {
                display: none;
            }

            span {
                display: block;
                position: absolute;
                height: 0.2rem;
                width: 100%;
                margin: 0;
                background: $color-dark-gray;
                border-radius: 5px;
                opacity: 1;
                left: 0;
                transform: rotate(0deg);
                transition: 0.2s ease-in-out;

                &:nth-child(1) {
                    top: 0px;
                }

                &:nth-child(2),
                &:nth-child(3) {
                    top: 9px;
                }

                &:nth-child(4) {
                    top: 18px;
                }
            }

            body.nav-open & {
                // width: 2.6rem;
                span {
                    &:nth-child(1) {
                        top: 16px;
                        width: 0%;
                        left: 50%;
                    }

                    &:nth-child(2) {
                        transform: rotate(45deg);
                    }

                    &:nth-child(3) {
                        transform: rotate(-45deg);
                    }

                    &:nth-child(4) {
                        top: 16px;
                        width: 0%;
                        left: 50%;
                    }
                }
            }
        }
    }
}
