.block-video {
    .video {
        & {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
        &-wrapper {
            position: relative;
            padding-bottom: 56.25%;
            height: 0;
            overflow: hidden;
        }
    }
}
