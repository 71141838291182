$field-gutter-x: 4.6rem / 2;
$field-gutter-y: 2.4rem;

@mixin placeholder {
    &.placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
    &::-webkit-input-placeholder {
        @content;
    }
}

@mixin formie-base() {
    .fui-hidden,
    .fui-alert-error,
    .fui-error-message {
        display: none;
    }

    .fui-sr-only {
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        position: absolute;
        width: 1px;
    }

    // ==========================================================================
    // Field Layout
    // ==========================================================================

    .fui-field {
        flex: 1;
    }

    .fui-label-left-input,
    .fui-label-right-input {
        .fui-field-container {
            display: flex;
            align-items: baseline;
        }

        .fui-input-container {
            flex: 1;
        }
    }

    .fui-label-above-input {
        // This causes alignment issues with errors in columns
        // align-self: flex-end;
    }

    .fui-label-below-input {
        // Force legends to display in the correct position.
        > .fui-field-container > .fui-input-container > .fui-fieldset > .fui-legend {
            display: block;
            float: left;
            clear: both;
            width: 100%;
        }
    }

    .fui-layout-horizontal .fui-layout-wrap {
        display: flex;
    }

    // ==========================================================================
    // Forms - Layout
    // ==========================================================================

    .fui-row {
        @include for-tablet-landscape-up {
            display: flex;
            flex-wrap: wrap;
        }
    }

    // Rows

    .fui-field-rows {
        @include for-tablet-landscape-up {
            margin-bottom: -#{$field-gutter-y};
        }
    }

    .fui-row {
        margin-left: -$field-gutter-x;
        margin-right: -$field-gutter-x;
        margin-bottom: $field-gutter-y;
    }
}

@mixin formie-input() {
    .fui-input,
    .fui-select {
        // Resets
        font-family: $font-base;
        font-weight: $font-weight-regular;
        // font-size: 0.875rem;
        font-size: 1.6rem;
        line-height: 1.75;
        margin: 0;
        appearance: none;
        color-adjust: exact;

        width: 100%;
        display: block;
        padding: 1.3rem 1.6rem;
        border: 1px solid white;

        &:focus {
            outline: 0;
            // border-color: $color-black;
            // box-shadow: $focus-shadow;
            box-shadow: 0 0 0 0.2rem rgba($color-dark-gray, 0.5);
        }

        &.fui-error {
            color: darken($color-secondary, 20%);
            border-color: darken($color-secondary, 20%);

            @include placeholder() {
                color: darken($color-secondary, 20%);
            }
        }

        &.fui-error:focus {
            // box-shadow: 0 0 0 0.1rem $color-secondary;
        }

        @include placeholder() {
            color: $color-dark-gray;
        }

        //
        // Input types
        //

        &[type="file"] {
            height: auto;
        }

        &[type="color"] {
            height: 39px;
            padding: 0.1rem;
        }

        // Fix for iOS devices and zooming on focus - must be 16px specifically
        // @media (max-width: 639px) {
        //     font-size: 16px;
        // }
    }

    .fui-select {
        &[size],
        &[multiple] {
            height: auto;

            option {
                padding: 0.1rem 0.4rem;
            }
        }

        &:not([multiple]):not([size]) {
            padding-right: 2rem;
            background-position: right 0.25rem center;
            background-repeat: no-repeat;
            background-size: 1.5em 1.5em;
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M7 7l3-3 3 3m0 6l-3 3-3-3' stroke='%239fa6b2' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        }

        &::-ms-expand {
            display: none;
        }
    }

    textarea.fui-input {
        min-height: 14.4rem;
    }
}

@mixin formie-checkbox-radio() {
    .fui-checkbox,
    .fui-radio {
        // Resets
        position: relative;
        font-family: inherit;
        // font-size: 0.875rem;
        line-height: 1;
        margin: 0;
        appearance: none;
        user-select: none;
        color-adjust: exact;

        &:not(:last-child) {
            margin-bottom: 0.25rem;
        }

        input {
            clip: rect(0, 0, 0, 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            position: absolute;
            width: 1px;

            &:focus {
                & + label {
                    &::before {
                        border-color: $color-dark-gray;
                        // box-shadow: $focus-shadow;
                    }
                }
            }

            &:checked {
                & + label {
                    // font-weight: $font-weight-medium;

                    // &::before {
                    //     border-color: blue;
                    // }
                }
            }

            &:active {
                & + label {
                    &::before {
                        border-color: lighten($color-primary, 15%);
                        background-color: lighten($color-primary, 15%);
                    }
                }
            }

            &.fui-error {
                & + label {
                    &::before {
                        // border-color: lighten(darken($color-secondary, 20%), 20%);
                        border-color: darken($color-secondary, 20%);
                    }
                }
            }

            &.fui-error:focus {
                & + label {
                    &::before {
                        box-shadow: 0 0 0 0.1rem rgba(darken($color-secondary, 20%), .45);
                    }
                }
            }
        }

        label {
            position: relative;
            padding-left: 2.8rem;
            line-height: 2.5rem; // rem is deliberate
            display: inline-block;
            color: white;
            transition: all $anim-duration-default cubic-bezier(0.4, 0, 0.2, 1);

            &::before {
                position: absolute;
                top: 2px;
                left: 0;
                display: block;
                width: 2rem;
                height: 2rem;
                content: "";
                cursor: pointer;
                border: 1px solid white;
                background-color: white;
                border-radius: 0.2rem;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 50% 50%;
                transition: all $anim-duration-default cubic-bezier(0.4, 0, 0.2, 1);
            }

            a {
                text-underline-position: under;
    
                &:hover {
                    text-decoration-color: transparent;
                }
            }
            // a {
            //     text-decoration-color: $color-primary;
            //     text-underline-position: under;

            //     &:hover {
            //         text-decoration-color: transparent;
            //     }
            // }
        }
    }

    .fui-layout-horizontal .fui-layout-wrap > .fui-radio,
    .fui-layout-horizontal .fui-layout-wrap > .fui-checkbox {
        margin-right: 1rem;
    }
    // ==========================================================================
    // Fields - Checkbox
    // ==========================================================================

    .fui-checkbox {
        label::before {
            // border-radius: 2px;
            border-radius: 50%;
        }

        input:checked + label::before {
            // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.83' height='10.41' viewBox='0 0 13.83 10.41' fill='%233b3a36'%3E%3Cpath d='M4.41,10.41a1,1,0,0,1-.7-.29L0,6.42,1.41,5l3,3,8-8,1.42,1.41L5.12,10.12A1,1,0,0,1,4.41,10.41Z' /%3E%3C/svg%3E");
            // background-size: 13.83px auto;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23E5AAA8'/%3E%3C/svg%3E");
            background-size: 1.2rem auto;
        }
    }

    // ==========================================================================
    // Fields - Radio
    // ==========================================================================

    .fui-radio {
        label::before {
            border-radius: 50%;
        }

        input:checked + label::before {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%233B3A36'/%3E%3C/svg%3E");
            background-size: 1.2rem auto;
        }
    }
}

@mixin formie-field() {
    .fui-field {
        padding-left: $field-gutter-x;
        padding-right: $field-gutter-x;
    }

    .fui-label-left-input {
        .fui-input-container {
            margin-left: $field-gutter-x;
        }
    }

    .fui-label-right-input {
        .fui-input-container {
            margin-right: $field-gutter-x;
        }
    }
    // ==========================================================================
    // Field Labels
    // ==========================================================================

    .fui-label,
    .fui-legend {
        font-size: 1.4rem;
        font-weight: $font-weight-medium;
        line-height: 1.7;
        display: block;
        padding: 0;
        color: white;
        text-transform: uppercase;
    }

    .fui-label-above-input .fui-label,
    .fui-label-above-input .fui-legend,
    .fui-subfield-label-above-input .fui-label,
    .fui-subfield-label-above-input .fui-legend {
        margin-bottom: 0.5rem;
    }

    .fui-label-below-input .fui-label,
    .fui-label-below-input .fui-legend,
    .fui-subfield-label-below-input .fui-label,
    .fui-subfield-label-below-input .fui-legend {
        margin-top: 0.5rem;
    }

    .fui-error .fui-legend {
        // color: red;
        // border-color: lighten(red, 20%);
    }
}

@mixin formie {
    @include formie-base();
    @include formie-field();
    @include formie-input();
    @include formie-checkbox-radio();

    .fui-submit {
        @extend .button, .button--tertiary;
    }

    .fui-alert-success {
        color: white;
        margin-bottom: 2.4rem;
        background-color: $color-primary-2;
        padding: 0.6rem 1.4rem;
    }
}
