.block-sectors {
    .sectors {
        text-align: center;

        &__title {
            @extend %heading-1;
            margin-bottom: 4rem;

            @include for-tablet-landscape-up {
                margin-bottom: 6.4rem;
            }

            @include for-medium-desktop-up {
                margin-bottom: 8rem;
            }
        }

        &__list {
            &:not(:last-child) {
                margin-bottom: 6.4rem;
            }

            $gutter-x: 4.8rem;
            $gutter-y: 4.8rem;

            display: grid;
            gap: 2.4rem 0;
            grid-template: auto / repeat(2, minmax(0, 1fr));

            @include for-tablet-landscape-up {
                gap: 4.8rem 0;
                grid-template: auto / repeat(3, minmax(0, 1fr));
            }

            @include for-desktop-up {
                gap: 4.8rem 0;
                grid-template: auto / repeat(5, minmax(0, 1fr));
            }

            @include for-medium-desktop-up {
                gap: 4.8rem 0;
            }

            .sector-card {
                width: 100%;
                display: block;
                text-decoration: none;
                text-align: left;
                user-select: none;

                &__image {
                    margin-bottom: 0.8rem;
                }

                &__label {
                    @extend %label;
                    text-decoration: underline;
                    text-underline-position: under;
                    text-decoration-color: transparent;
                    transition: text-decoration-color $anim-duration-default ease-in-out,
                        color $anim-duration-default ease-in-out;
                    text-align: center;

                    word-break: break-word;
                    hyphens: auto;
                }

                &:hover {
                    .sector-card {
                        &__label {
                            text-decoration-color: currentColor;
                            color: $color-primary-3;
                        }
                    }
                }
            }
        }
    }
}
