.block-masonry {
    .masonry {
        &__title {
            @extend %heading-1;
            text-align: center;
            margin-bottom: 4rem;

            @include for-tablet-landscape-up {
                margin-bottom: 6.4rem;
            }

            @include for-medium-desktop-up {
                margin-bottom: 8rem;
            }
        }

        &__images {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: -32px; // offset gutter created by macy

            figure {
                width: 100%;

                @include for-tablet-portrait-up {
                    width: calc(50% - 12px);
                }

                @include for-desktop-up {
                    width: calc(33.3333% - 32px);
                }

                picture {
                    position: relative;
                    overflow: hidden;

                    img {
                        transition: transform $anim-duration-default ease-in-out;
                    }
                }

                a {
                    display: block;

                    &:hover {
                        img {
                            transform: scale(1.05);
                        }
                    }
                }
            }
        }
    }
}
