.block-fluid-image {
    img {
        width: 100%;
        object-fit: cover;
        height: 65vw;
        // @include for-tablet-landscape-up {
        //     height: 41.9rem;
        // }
    }

    &--normal {
        img {
            @include for-tablet-landscape-up {
                height: 41.9rem;
            }
        }
    }

    &--large {
        img {
            @include for-tablet-landscape-up {
                height: 60rem;
            }
        }
    }

    &--inline {
        img {
            width: 100%;
            height: auto;
            object-fit: contain;
        }
    }
}
