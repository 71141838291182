.block {
    padding-top: $block-padding-mobile;
    padding-bottom: $block-padding-mobile;

    @include for-tablet-landscape-up {
        padding-top: $block-padding-landscape;
        padding-bottom: $block-padding-landscape;
    }

    @include for-desktop-up {
        padding-top: $block-padding-desktop;
        padding-bottom: $block-padding-desktop;
    }

    @include for-medium-desktop-up {
        padding-top: $block-padding-medium-desktop;
        padding-bottom: $block-padding-medium-desktop;
    }

    &--white {
        background-color: white;
    }

    &--yellow {
        background-color: $color-tertiary-1;
    }

    &--no-padding {
        padding-top: 0;
        padding-bottom: 0;
    }

    &--no-padding-top {
        padding-top: 0;
    }

    &--no-padding-bottom {
        padding-bottom: 0;
    }
}

.block--white + .block--white,
.block--yellow + .block--yellow {
    padding-top: 0;
}
