.block-usps {
    .usps {
        @include for-desktop-up {
            display: flex;
        }

        &__title {
            @extend %heading-1;
            text-align: center;

            margin-bottom: 4rem;

            @include for-tablet-landscape-up {
                margin-bottom: 6.4rem;
            }

            @include for-desktop-up {
                width: 100%;
                max-width: 29.2rem;

                margin-right: 2.4rem;
                margin-bottom: 0;
                text-align: left;
            }

            @include for-medium-desktop-up {
                margin-top: 1.4rem;
                margin-right: 6.4rem;
            }
        }

        &__content {
            flex-grow: 1;

            .usp-list {
                $gutter-x: 10rem;
                $gutter-y: 4.8rem;

                display: grid;
                gap: 2.4rem;
                grid-template: auto / 1fr;

                @include for-tablet-landscape-up {
                    gap: 4.8rem 4.8rem;
                    grid-template: auto / repeat(3, minmax(0, 1fr));
                }

                @include for-desktop-up {
                    grid-template: auto / repeat(3, minmax(0, 1fr));
                }
            }

            .usp-card {
                display: flex;
                align-items: center;
                justify-content: center;

                @include for-desktop-up {
                    justify-content: flex-start;
                }

                &__icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 100%;
                    // background-color: white;
                    flex-shrink: 0;
                    width: 8rem;
                    height: 8rem;
                    padding: 2rem;

                    @include for-desktop-up {
                        width: 8.5rem;
                        height: 8.5rem;
                        padding: 1.5rem;
                    }

                    @include for-medium-desktop-up {
                        width: 10.8rem;
                        height: 10.8rem;
                        padding: 2.5rem;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                &__label {
                    @extend %label;
                    word-break: break-word;
                    hyphens: auto;
                    margin-left: 1.6rem;
                }
            }
        }
    }
}
