.block-cta {
    .cta {
        text-align: center;

        &__title {
            @extend %heading-1;
            
            &:not(:last-child) {
                margin-bottom: 2.4rem;
            }
        }

        &__text {
            @extend %text;

            &:not(:last-child) {
                margin-bottom: 2.4rem;
            }
        }
    }
}
