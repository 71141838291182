.block-form {
    overflow: hidden;
    background-color: $color-primary-3;

    .container {
        position: relative;
    }

    .form {
        @include for-tablet-landscape-up {
            display: flex;
        }

        &__image {
            width: 100%;

            @include for-tablet-landscape-up {
                width: 50%;
            }

            @media (min-width: 1440px) {
                margin-left: calc(-1 * (50vw - #{$container-width-regular} / 2));
                width: calc(50% + (50vw - #{$container-width-regular} / 2));
            }

            picture {
                height: 100%;
            }

            img {
                height: 100%;
                object-fit: cover;
            }
        }

        &__content {
            width: 100%;
            padding: $block-padding-mobile $container-gutter-mobile;

            @include for-tablet-landscape-up {
                width: 50%;
                padding: $block-padding-landscape $container-gutter-landscape;
                align-self: center;
            }

            @include for-desktop-up {
                padding: $block-padding-desktop;
                padding-right: $container-gutter-landscape;
            }

            @media (min-width: 1550px) {
                padding-right: 0;
            }
        }

        &__title {
            @extend %heading-1;
            color: white;
            margin-bottom: 2.4rem;
        }

        &__text {
            @extend %text;
            margin-bottom: 2.4rem;
            p,
            ol,
            ul {
                font-weight: $font-weight-regular;
                font-size: 1.8rem;
                color: white;
            }
        }

        &__form {
            @include formie();
            // max-width: 60rem;
        }
    }
}
