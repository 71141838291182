%button-base {
    display: inline-block;
    outline: none;
    appearance: none;
    text-decoration: none;
    user-select: none;
    color: inherit;
    text-align: center;

    font-size: 1.6rem;
    font-weight: $font-weight-medium;
    line-height: 1.1875;
    text-decoration: underline;
    text-underline-position: under;

    cursor: pointer;

    &[disabled] {
        cursor: not-allowed;
        opacity: 0.56;
    }
}

.button {
    @extend %button-base;
    transition: background-color $anim-duration-button ease-in-out,
        text-decoration-color $anim-duration-button ease-in-out,
        opacity $anim-duration-button ease-in-out;

    padding: 2.65rem 4rem;

    &:not([disabled]) {
        &:hover {
            text-decoration-color: transparent;
        }
    }

    &::before {
        content: "+ ";
    }

    &--primary {
        background-color: $color-primary;

        &:not([disabled]) {
            &:hover {
                background-color: $color-secondary;
            }
        }
    }

    &--secondary {
        background-color: $color-secondary;

        &:not([disabled]) {
            &:hover {
                background-color: $color-primary;
            }
        }
    }

    &--tertiary {
        background-color: $color-tertiary;

        &:not([disabled]) {
            &:hover {
                background-color: $color-primary;
            }
        }
    }
}

.button-link {
    @extend %button-base;

    color: $color-primary-4;
    transition: text-decoration-color $anim-duration-button ease-in-out;
    will-change: text-decoration-color;

    &::before {
        content: "+ ";
    }

    &:hover {
        text-decoration-color: transparent;
    }
}
