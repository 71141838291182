.block-quote {
    blockquote {
        position: relative;

        @include for-tablet-landscape-up {
            margin-left: 12rem;
        }

        &::before {
            content: "";
            display: block;
            width: 79.81px;
            height: 75.63px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='79.81' height='75.63' viewBox='0 0 79.81 75.63' fill='%2376a68f'%3E%3Cpath d='M61.43,0h-43A18.43,18.43,0,0,0,0,18.38V39.2A18.42,18.42,0,0,0,18.38,57.57H48.31L72.56,75.63,65.32,57.15A18.44,18.44,0,0,0,79.81,39.2V18.38A18.43,18.43,0,0,0,61.43,0Z' /%3E%3C/svg%3E");
            background-size: 79.81px 75.63px;
            background-repeat: no-repeat;
            margin-bottom: 2.4rem;

            @include for-tablet-landscape-up {
                margin-bottom: 0;
                position: absolute;
                top: -4.8rem;
                left: -4rem;
                transform: translateX(-100%);
            }
        }

        p {
            font-size: 2.4rem;
            line-height: 1.5;
            font-weight: $font-weight-light;
            color: $color-primary-4;
            @include for-tablet-landscape-up {
                font-size: 3.2rem;
            }
        }

        footer {
            margin-top: 3.2rem;
            padding-top: 3.2rem;
            border-top: 0.1rem solid $color-primary-2;

            font-weight: $font-weight-semibold;
            font-size: 1.6rem;
            line-height: 1.1875;
            color: $color-primary-4;
        }
    }
}
