.block-team {
    .team {
        &__title {
            @extend %heading-1;
            text-align: center;
            margin-bottom: 4rem;

            @include for-tablet-landscape-up {
                margin-bottom: 6.4rem;
            }

            @include for-medium-desktop-up {
                margin-bottom: 8rem;
            }
        }

        &__content {
            display: flex;
            justify-content: center;
            .team-list {
                display: flex;
                flex-wrap: wrap;

                margin-top: -2.4rem;
                margin-left: -2.4rem;
                max-width: 300px;

                @include for-tablet-portrait-up {
                    max-width: 650px;
                    margin-top: -4.8rem;
                    margin-left: -10rem;
                }

                @include for-tablet-landscape-up {
                    max-width: none;
                    justify-content: center;
                    margin-top: -8rem;
                    margin-left: -8rem;
                }
                
                @include for-desktop-up {
                    margin-left: -10rem;
                }

                &__item {
                    padding-top: 2.4rem;
                    padding-left: 2.4rem;
                    width: 100%;

                    @include for-tablet-portrait-up {
                        width: percentage(1/2);
                        padding-top: 4.8rem;
                        padding-left: 10rem;
                    }

                    @include for-tablet-landscape-up {
                        width: percentage(1/4);
                        padding-top: 8rem;
                        padding-left: 8rem;
                    }
                    
                    @include for-desktop-up {
                        padding-left: 10rem;
                    }
                }
            }

            .team-card {
                &__image {
                    margin-bottom: 2rem;
                }

                &__name {
                    @extend %label;
                    margin-bottom: 0.4rem;
                }

                &__function {
                    display: block;
                    font-size: 1.6rem;
                    font-weight: $font-weight-light;
                }
            }
        }
    }
}
