.container {
    width: 100%;
    margin: 0 auto;
    padding-left: $container-gutter-mobile;
    padding-right: $container-gutter-mobile;
    // background: teal;

    @include for-tablet-portrait-up {
        padding-left: $container-gutter-portrait;
        padding-right: $container-gutter-portrait;
    }

    @include for-tablet-landscape-up {
        max-width: $container-width-regular + ($container-gutter-landscape * 2);
        padding-left: $container-gutter-landscape;
        padding-right: $container-gutter-landscape;
    }
    
    &--no-padding {
        padding-left: 0;
        padding-right: 0;
        // background: teal;
    
        @include for-tablet-portrait-up {
            padding-left: 0;
            padding-right: 0;
        }
    
        @include for-tablet-landscape-up {
            max-width: $container-width-regular;
        }
    }

    &--medium {
        @include for-tablet-landscape-up {
            max-width: $container-width-medium + ($container-gutter-landscape * 2);
        }
    }

    &--small {
        @include for-tablet-landscape-up {
            max-width: $container-width-small + ($container-gutter-landscape * 2);
        }
    }

    &--tiny {
        @include for-tablet-landscape-up {
            max-width: $container-width-tiny + ($container-gutter-landscape * 2);
        }
    }
}
