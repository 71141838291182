.page--newsList,
.page--default {

    .container {
        // @include for-tablet-landscape-up {
        //     display: grid;
        //     // grid-gap: 1rem 3rem;
        //     grid-template-rows: minmax(0, auto) 1fr auto;
        //     grid-template-columns: 1fr minmax(0, 25%);
        // }
        // &-search {
        //     // background-color: red;
        //     @include for-tablet-landscape-up {
        //         grid-row: 1 / span 1;
        //         grid-column: 2 / span 1;
        //     }
        // }
        // &-list {
        //     @include for-tablet-landscape-up {
        //         grid-row: 1 / span 3;
        //         grid-column: 1 / span 1;
        //         margin-right: 3rem;
        //     }
        // }
        // &-sidebar {
        //     @include for-tablet-landscape-up {
        //         grid-row: 2 / span 2;
        //         grid-column: 2 / span 1;
        //         margin-top: 1rem;
        //     }
        // }
    }

    .news-list {
        display: flex;
        flex-wrap: wrap;
        margin: 60px -15px;
        &__item {
            margin: 0 15px 60px;
            width: 100%;
            @include for-tablet-portrait-up {
                margin-bottom: 30px;
                width: calc( (100% - 2 * 30px) / 2);
            }
            @include for-tablet-landscape-up {
                width: calc( (100% - 3 * 30px) / 3);
            }
            @include for-desktop-up {
                
            }
            @include for-medium-desktop-up {
                // width: calc( (100% - 3 * 30px) / 3);
                width: calc( (100% - 4 * 30px) / 4);
            }
        }

        &__search {
            & {
                display: block;
                width: 100%;
                max-width: 500px;
            }
            &-form {
                margin: 20px 0 30px 0;
                display: flex;
                align-items: center;
                max-width: 100%;
                & > * {
                    display: block;
                    border: none;
                    font-size: 18px;
                    appearance: none;
                }
                & > span {
                    border: 1px solid $color-primary-4;
                    border-right: none;
                    border-radius: 20px 0 0 20px;
                    padding: 14px 15px 14px 15px;
                    display: block;
                    width: 49px;
                    svg {
                        display: block;
                        width: 18px;
                    }
                }
                input[type="search"] {
                    display: block;
                    width: auto;
                    border: none;
                    border-top: 1px solid $color-primary-4;
                    border-bottom: 1px solid $color-primary-4;
                    padding: 13px 20px 13px 0;
                    outline: none;
                    width: 100%;
                    line-height: 0.5;
                    
                }
                button[type="submit"] {
                    display: inline-flex;
                    width: 57px;
                    align-items: center;
                    border: 1px solid $color-primary-4;
                    border-left: none;
                    border-radius: 0 20px 20px 0;
                    padding: 16px 20px 16px;
                    color: $color-primary-4;
                    transition: all .2s ease-out;
                    cursor: pointer;
                    span {
                        display: none;
                        visibility: hidden;
                    }
                    svg {
                        display: block;
                    }
                    &:hover {
                        background-color: $color-primary-3;
                        color: white;
                    }
                }
            }
            &-title {
                @extend %heading-1;
                margin: 2.5rem 0 40px;
            }
        }

        &__pagination {
            & {
                display: block;
                display: flex;
                justify-content: center;
                margin: 50px 0;
            }
            & > li {
                & {
                    margin: 0 5px;
                }
                & > a,
                & > span.current {
                    display: flex;
                    justify-content: center;
                    width: 30px;
                    padding: 3px 10px;
                    border: 1px solid $color-primary-4;
                    border-radius: 5px;
                    text-decoration: none;
                }
                & > a {
                    &:hover {
                        border-color: $color-primary-3;
                        background-color: $color-primary-3;
                        color: white;
                    }

                }
                & > span.current {
                    background-color: $color-primary-4;
                    color: white;
                }
            }
        }

        &__archive {
            color: $color-dark-gray;
            font-size: 14px;
            margin-bottom: 50px;
            &-title {
                margin-bottom: 1.5rem;
                padding-bottom: 1.5rem;
                border-bottom: 1px solid #EEE;
            }
            & > li {
                &:not(:first-child) {
                    margin-top: 1.5rem;
                }
                display: flex;
                & > a {
                    display: block;
                    width: 50px;
                    text-decoration: none;
                    font-weight: 600;
                    transition: all 0.3s ease;
                    &:hover {
                        color: $color-primary-3;
                        text-decoration: underline;
                    }
                }
                & > ul {
                    display: flex;
                    flex-wrap: wrap;
                    li {
                        &::before {
                            content: "-";
                            margin: 0 5px;
                        }
                        a {
                            text-decoration: none;
                            transition: all 0.3s ease;
                            span {
                                opacity: 0.5;
                                transition: all 0.3s ease;
                            }
                            &:hover {
                                text-decoration: underline;
                                color: $color-primary-3;
                                span {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }

        .news {
            &-image {
                display: block;
                width: 50%;
                padding-top: 50%;
                margin-bottom: 2rem;
                position: relative;
                img, 
                svg {
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                }
            }
            &-date {
                text-transform: uppercase;
                font-size: 12px;
            }
            &-title {
                margin-bottom: 15px;
                a {
                    text-decoration: none;
                    &:hover {
                        color: $color-primary-3;
                    }
                }
            }
            &-intro {
                margin-bottom: 15px;
            }
        }
    }

}