.block-two-column-text {
    .two-column-text {
        @include for-tablet-landscape-up {
            display: grid;
            grid-template: auto auto / repeat(2, minmax(0, 1fr));
            gap: 0rem 10.4rem;
        }

        &__title {
            @extend %heading-2;
            margin-bottom: 2.4rem;

            &--left {
                grid-row: 1 / span 1;
                grid-column: 1 / span 1;
            }

            &--right {
                grid-row: 1 / span 1;
                grid-column: 2 / span 1;
            }
        }

        &__text {
            @extend %text;

            &:not(:last-child) {
                margin-bottom: 3.6rem;

                @include for-tablet-landscape-up {
                    margin-bottom: 0;
                }
            }

            &--left {
                grid-row: 2 / span 1;
                grid-column: 1 / span 1;
            }

            &--right {
                grid-row: 2 / span 1;
                grid-column: 2 / span 1;
            }
        }
    }
}
