.block-expertises {
    .expertises {
        text-align: center;

        &__heading {
            margin-bottom: 4rem;

            @include for-tablet-landscape-up {
                margin-bottom: 6.4rem;
            }

            @include for-medium-desktop-up {
                margin-bottom: 8rem;
            }
        }

        &__title {
            @extend %heading-1;

            &:not(:last-child) {
                margin-bottom: 2.4rem;
            }
        }

        &__text {
            @extend %text;
            font-size: 1.8rem;
            max-width: 72rem;
            margin: 0 auto;
        }

        &__list {
            &:not(:last-child) {
                margin-bottom: 6.4rem;
            }

            $gutter-x: 4.8rem;
            $gutter-y: 4.8rem;

            display: grid;
            gap: 2.4rem;
            grid-template: auto / repeat(2, minmax(0, 1fr));

            @include for-tablet-landscape-up {
                gap: 4.8rem 4.8rem;
                grid-template: auto / repeat(3, minmax(0, 1fr));
            }

            @include for-desktop-up {
                gap: 4.8rem 6rem;
            }

            @include for-medium-desktop-up {
                gap: 4.8rem 4.8rem;
            }

            .expertise-card {
                width: 100%;
                display: block;
                text-decoration: none;
                text-align: left;
                user-select: none;

                &__icon {
                    margin-bottom: 0.8rem;
                }

                &__label {
                    @extend %label;
                    text-decoration: underline;
                    text-underline-position: under;
                    text-decoration-color: transparent;
                    transition: text-decoration-color $anim-duration-default ease-in-out,
                        color $anim-duration-default ease-in-out;
                    word-break: break-word;
                    hyphens: auto;
                }

                &:hover {
                    .expertise-card {
                        &__label {
                            text-decoration-color: currentColor;
                            color: $color-primary-3;
                        }
                    }
                }
            }

            &--6-columns {
                @include for-tablet-portrait-up {
                    max-width: 60rem;
                    margin-left: auto;
                    margin-right: auto;
                }
                @include for-tablet-landscape-up {
                    max-width: 90rem;
                }

                @include for-desktop-up {
                    grid-template: auto / repeat(6, minmax(0, 1fr));
                    max-width: none;
                }

                .expertise-card {
                    @include for-tablet-portrait-up {
                        max-width: percentage(2.4/3);
                        margin: 0 auto;
                    }
                    @include for-tablet-landscape-up {
                        max-width: none;
                    }
                }
            }

            &--3-columns {
                @include for-tablet-portrait-up {
                    max-width: 60rem;
                    margin-left: auto;
                    margin-right: auto;
                }
                @include for-tablet-landscape-up {
                    max-width: 90rem;
                }

                .expertise-card {
                    @include for-tablet-portrait-up {
                        max-width: percentage(2.4/3);
                        margin: 0 auto;
                    }
                    @include for-tablet-landscape-up {
                        max-width: percentage(2.4/3);
                    }
                }
            }
        }
    }
}
