.block-locations {
    .locations {
        &__title {
            @extend %heading-1;
            text-align: center;
            margin-bottom: 4rem;

            @include for-tablet-landscape-up {
                margin-bottom: 6.4rem;
            }

            @include for-medium-desktop-up {
                margin-bottom: 8rem;
            }
        }

        &__content {
            .locations-list {
                &__item {
                    &:not(:first-child) {
                        margin-top: 4.8rem;
                    }

                    @include for-tablet-landscape-up {
                        &:nth-child(odd) {
                            .location-card {
                                flex-direction: row-reverse;
                            }
                        }

                        &:nth-child(even) {
                            .location-card {
                                flex-direction: row;
                            }
                        }
                    }
                }
            }

            .location-card {
                display: flex;
                flex-direction: column-reverse;
                align-items: center;
                background-color: white;

                &__content,
                &__image {
                    width: 100%;

                    @include for-tablet-landscape-up {
                        width: 50%;
                    }
                }

                &__content {
                    padding: 3.6rem;

                    @include for-tablet-landscape-up {
                        align-self: center;
                        padding: 7rem 6rem;
                    }

                    @include for-desktop-up {
                        padding: 9.6rem 12rem;
                    }
                }

                &__image {
                    picture {
                        // height: 100%;

                        img {
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }

                &__title {
                    @extend %heading-2;

                    &:not(:last-child) {
                        margin-bottom: 2.4rem;
                    }
                }

                &__text {
                    @extend %text;

                    &:not(:last-child) {
                        margin-bottom: 2.4rem;
                    }
                }

                &__address, 
                &__phone,
                &__email {
                    text-decoration: none;
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                    &:hover {
                        text-decoration: underline;
                    }
                    svg {
                        display: block;
                        margin-right: 10px;
                        path {
                            fill: $color-primary-4;
                        }
                    }
                }

                &__hours {
                    margin-top: 25px;
                    tr {
                        &.today {
                            font-weight: bold;
                        }
                        td {
                            font-size: 14px;
                            @include for-tablet-landscape-up {
                                font-size: inherit;
                            }
                            & + td {
                                padding-left: 20px;
                            }
                            &:first-child {
                                width: 35px;
                            }
                        }
                    }
                    & + .location-card__text {
                        margin-top: 1.6rem;
                    }
                }

                .button-link {
                    &:not(:first-of-type) {
                        margin-top: 1.6rem;
                    }
                }
            }
        }
    }
}
