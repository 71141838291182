.block-event {
    .event {
        @include for-tablet-landscape-up {
            display: grid;
            grid-template: auto auto auto auto / 1fr 1fr;
            gap: 0 4.6rem;
        }

        &__title {
            grid-row: 1 / span 1;
            grid-column: 1 / span 1;

            @extend %heading-1;
            margin-bottom: 2.4rem;
        }

        &__info {
            grid-row: 2 / span 1;
            grid-column: 1 / span 1;
            margin-bottom: 2.4rem;

            @include for-tablet-landscape-up {
                margin-bottom: 0;
            }

            @include for-desktop-up {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }

            .info {
                display: flex;
                align-items: center;

                svg {
                    flex-shrink: 0;
                    margin-right: 0.8rem;
                    align-self: flex-start;
                }

                span {
                    display: inline-block;
                }

                margin-bottom: 1.2rem;

                @include for-desktop-up {
                    margin-right: 2.4rem;
                }

                &--date {
                    svg {
                        transform: translateY(-0.2rem);
                    }
                }
            }
        }

        &__description {
            grid-row: 1 / span 3;
            grid-column: 2 / span 1;
        }

        &__embed {
            grid-row: 4 / span 1;
            grid-column: 1 / span 2;
            margin-top: 4rem;
            height: 40rem;
            width: 100%;
            background-color: white;
        }

        // Content

        &__pretitle {
            @extend %pretitle;
            margin-bottom: 1.5rem;
        }

        &__text {
            @extend %text;
            font-size: 1.8rem;
        }
    }
}
