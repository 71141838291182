.block-newsletter {
    @media (max-width: 599px) {
        padding-top: 0;

        .container {
            padding-left: 0;
            padding-right: 0;
        }

        &.block--white {
            padding-bottom: 0;
        }
    }
    .container {
        @include for-tablet-landscape-up {
            max-width: $container-width-medium;
        }
    }

    .newsletter {
        position: relative;

        &__content {
            position: relative;
            width: 100%;
            max-width: 56rem + 4.8rem;
            margin: 0 auto;
            padding-left: 2.4rem;
            padding-right: 2.4rem;
            padding-top: 9rem;
            padding-bottom: 9rem;
            z-index: 1;
            text-align: center;
        }

        &__image,
        &__background {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }

        &__background {
            &::before {
                background-color: $color-primary-3;
            }
        }
        &__image {
            &::before {
                background-color: rgba($color-primary-4, 0.66);
            }

            picture {
                height: 100%;
                img {
                    object-fit: cover;
                    height: 100%;
                    // height: 48.2rem;
                }
            }
        }

        &__title {
            @extend %heading-giga;
            margin-bottom: 3.2rem;
            color: white;
        }

        &__form {
            .fui-page {
                @include for-tablet-landscape-up {
                    display: flex;
                    flex-direction: row;
                }

                .fui-page-container {
                    @include for-tablet-landscape-up {
                        flex-grow: 1;
                    }
                }
            }

            .fui-error-message {
                display: none;
            }

            .fui-alert {
                color: white;
                font-size: 1.6rem;
                margin-bottom: 1.2rem;

                &-error {
                    display: none;
                    // color: darken($color-secondary, 45%);
                }
            }

            .fui-field-container {
                position: relative;
                // width: 37.8rem;

                .fui-input {
                    // @include font-size(14, 22);
                    font-size: 1.6rem;
                    line-height: 1.75;
                    background-color: white;
                    border: none;
                    outline: none;
                    margin: 0;
                    padding: 2.2rem 4rem;
                    width: 100%;

                    font-weight: $font-weight-medium;
                    color: black;
                    transition: color $anim-duration-default;
                    text-align: center;

                    @include for-tablet-landscape-up {
                        min-width: 31.5rem;
                        text-align: left;
                    }

                    @include placeholder() {
                        color: $color-dark-gray;
                        transition: color $anim-duration-default;
                    }

                    &.fui-error {
                        // background-color: lighten($color-secondary, 10%);

                        color: darken($color-secondary, 20%);

                        @include placeholder() {
                            color: darken($color-secondary, 20%);
                        }
                    }
                }
            }
            .fui-submit {
                @extend .button, .button--tertiary;
                width: 100%;
                margin-top: 1.2rem;

                @include for-tablet-landscape-up {
                    margin-top: 0;
                    width: auto;
                }
            }
        }
    }
}
