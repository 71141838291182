.block-gallery {
    .gallery {
        position: relative;
    }
    .swiper {
        &-container {
            margin-left: -$container-gutter-mobile;
            margin-right: -$container-gutter-mobile;

            @include for-tablet-portrait-up {
                margin-left: auto;
                margin-right: auto;
            }
        }

        &-slide {
            img {
                object-fit: cover;
                height: 80vw;

                @include for-tablet-portrait-up {
                    height: auto;
                }
            }
        }

        &-pagination {
            margin-top: 2.8rem;
            display: flex;
            justify-content: center;

            &-bullet {
                position: relative;
                display: block;
                outline: none;
                user-select: none;
                width: 0.4rem;
                height: 0.4rem;
                border-radius: 100%;
                background-color: $color-black;
                cursor: pointer;
                margin-left: 0.8rem;
                margin-right: 0.8rem;

                &::after {
                    content: "";
                    display: block;
                    width: 0.8rem;
                    height: 0.8rem;
                    border: 0.1rem solid transparent;
                    border-radius: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    transition: border-color $anim-duration-button ease-in-out;
                    will-change: border-color;
                }

                &-active {
                    &::after {
                        border-color: $color-black;
                    }
                }
            }
        }

        &-button {
            &-prev,
            &-next {
                display: none;
                @include for-tablet-landscape-up {
                    display: block;
                    position: absolute;
                    width: 6.5rem;
                    height: 6.5rem;
                    background-color: $color-secondary;
                    z-index: 1;
                    transform: translateY(-50%);
                    top: 50%;
                    cursor: pointer;
                    transition: background-color $anim-duration-button ease-in-out;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15'%3E%3Cpath d='M14.5,7H1.71L7.85.85a.48.48,0,0,0,0-.7.48.48,0,0,0-.7,0l-7,7A.36.36,0,0,0,0,7.31a.5.5,0,0,0,0,.38.36.36,0,0,0,.11.16l7,7a.48.48,0,0,0,.7,0,.48.48,0,0,0,0-.7L1.71,8H14.5a.5.5,0,0,0,0-1Z' /%3E%3C/svg%3E");
                    background-size: 1.5rem 1.5rem;
                    background-position: center;
                    background-repeat: no-repeat;

                    &:hover {
                        background-color: $color-primary;
                    }
                }
            }

            &-prev {
                @include for-tablet-landscape-up {
                    left: 0;
                }

                @include for-medium-desktop-up {
                    left: -6.5rem;
                }
            }

            &-next {
                @include for-tablet-landscape-up {
                    transform: scale(-1, 1) translateY(-50%);
                    right: 0;
                }

                @include for-medium-desktop-up {
                    right: -6.5rem;
                }
            }
        }
    }
}
