.block-text {
    .news-date {
        display: block;
        text-transform: uppercase;
        font-size: 12px;
    }
    .text {
        &__title {
            @extend %heading-2;
            margin-bottom: 2.4rem;
            &--icon {
                display: grid;
                grid-template-rows: minmax(0, auto) auto;
                grid-template-columns: minmax(0, 6.4rem) minmax(0, 1fr);
                .news-date {
                    // background: palegoldenrod;
                    grid-column: 2 / span 1;
                    grid-row: 1 / span 1;
                }
                .text__title {
                    // background: palegreen;
                    grid-column: 2 / span 1;
                    grid-row: 2 / span 1;
                }
                .text__title__icon {
                    // background: palevioletred;
                    grid-column: 1 / span 1;
                    grid-row: 1 / span 2;
                }
            }
            &__icon {
                width: auto;
                height: 4.8rem;
                display: inline-block;
                margin: 0;
                // transform: translateY(1rem);
                margin-right: 1.6rem;
            }
        }

        &__text {
            @extend %text;

            &:not(:last-child) {
                margin-bottom: 3.6rem;

                @include for-tablet-landscape-up {
                    margin-bottom: 0;
                }
            }
        }
    }
}
