.page-error {
    padding-top: 6rem;
    padding-bottom: 6rem;
    text-align: center;
    min-height: 39.5vh;
    display: flex;
    align-items: center;

    &__title {
        @extend %heading-1;
        margin-bottom: 2.4rem;
    }
    
    &__text {
        @extend %text;
        margin-bottom: 2.4rem;
    }

    &__back {
        @extend .button, .button--primary;
    }
}
