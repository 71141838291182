html {
    font-size: 62.5%;
}

body {
    font-family: $font-base;
    font-weight: $font-weight-regular;
    font-size: 1.6rem;
    line-height: 1.667;
    color: $color-black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.nav-open {
        overflow: hidden;
        @include for-desktop-up {
            overflow: visible;
        }
    }
}

// Address
address {
    font: inherit;
}

// Image related
picture {
    display: block;
}

img {
    display: block;
    width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $color-primary-4;
}

// Links
a {
    color: inherit;
    transition: color 0.2s ease-in-out, text-decoration-color 0.2s ease-in-out;
}

// Quote
q {
    &::before {
        display: block;
        content: "“";
    }

    &::after {
        content: none;
    }
}

// Number input
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
}

input,
button,
optgroup,
select,
textarea {
    font-family: $font-base;
}

// Helpers
.visually-hidden {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    height: 1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
}

.grecaptcha-badge {
    display: none;
    visibility: hidden;
}