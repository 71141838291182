// Colors //

// Primary
$color-primary: #b8dccd;
$color-primary-2: #76a68f;
$color-primary-3: #417061;
$color-primary-4: #213a32;

// Secondary
$color-secondary: #f8c3bd;
$color-secondary-2: #e5aaa8;

// Other
$color-tertiary: #fff6e4;
$color-tertiary-1: #FDF9F2;

$color-black: #0f0f0f;
$color-light-gray: #9D9D9F;
$color-dark-gray: #666666;
// Fonts //

// Families
$font-base: "Montserrat", sans-serif;

// Weights
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;

// Utilities //

// Block
$block-padding-mobile: 5rem;
$block-padding-landscape: 7rem;
$block-padding-desktop: 9rem;
$block-padding-medium-desktop: 12rem;

// Container
$container-width-regular: 144rem;
$container-width-tiny: 96rem;
$container-width-small: 120rem;
$container-width-medium: 160rem;

$container-gutter-mobile: 2.4rem;
$container-gutter-portrait: 3.6rem;
$container-gutter-landscape: 4.8rem;

// Transitions //
$anim-duration-default: 0.2s;
$anim-duration-button: 0.2s;

// Header //
$header-height-mobile: 8rem;
$header-height-desktop: 12rem;