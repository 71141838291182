.block-image-text {
    .image-text {
        // background-color: white;
        display: flex;
        flex-direction: column-reverse;

        @include for-tablet-landscape-up {
            flex-direction: row-reverse;
        }

        &__content,
        &__image {
            width: 100%;

            @include for-tablet-landscape-up {
                position: sticky;
                top: 0;
                align-self: flex-start;
                width: 50%;
            }
        }

        &__content {
            padding: 3.6rem 0;

            @include for-tablet-landscape-up {
                // align-self: center;
                padding: 7rem 6rem;
            }

            @include for-desktop-up {
                padding: 9rem;
            }
        }

        &__image {
            picture {
                // height: 100%;

                img {
                    // height: 100%;
                    object-fit: cover;
                }
            }
        }

        &__pretitle {
            @extend %pretitle;
            margin-bottom: 1.8rem;
        }

        &__title {
            @extend %heading-2;

            &:not(:last-child) {
                margin-bottom: 2.4rem;
            }
        }

        &__text {
            @extend %text;

            &:not(:last-child) {
                margin-bottom: 2.4rem;
            }
        }
    }

    &--reversed {
        .image-text {
            // flex-direction: column-reverse;

            @include for-tablet-landscape-up {
                flex-direction: row;
            }
        }
    }

    &.block--white {
        .image-text {
            
        }
    }
}
