.block-hero {
    @include for-desktop-up {
        margin-top: -12rem;
    }

    .container {
        position: relative;
        z-index: 3;
    }

    .hero-gallery {
        position: relative;

        &__slogan {
            position: absolute;
            top: 50%;
            left: 50%;
            // transform: translateX(-50%) translateY(-50%);
            z-index: 4;
            color: white;
            line-height: 1.2;
            font-weight: $font-weight-semibold;
            text-transform: uppercase;
            text-align: center;
            width: 100%;
            max-width: 80%;
            margin-left: auto;
            margin-right: auto;
            font-size: 2.4rem;
            @include for-tablet-landscape-up {
                font-size: 3rem;
            }

            &--small {
                @include for-tablet-landscape-up {
                    font-size: 3rem;
                }
            }

            &--medium {
                @include for-tablet-landscape-up {
                    font-size: 4rem;
                }
            }

            &--large {
                @include for-tablet-landscape-up {
                    font-size: 6rem;
                }
            }

            &[data-aos="hero-slogan"] {
                opacity: 0;
                transform: translateX(-50%) translateY(-50%) translateY(5rem);
                transition-property: transform, opacity;

                &.aos-animate {
                    opacity: 1;
                    transform: translateX(-50%) translateY(-50%);
                }
            }
        }

        .swiper {
            &-slide {
                img {
                    object-fit: cover;
                    height: 80vw;

                    @include for-tablet-landscape-up {
                        height: 86rem;
                        max-height: 100vh;
                    }
                }
            }

            &-pagination {
                display: flex;
                flex-direction: column;
                position: absolute;
                left: 2.4rem;
                top: 50%;
                transform: translateY(-50%);
                z-index: 4;

                &-bullet {
                    position: relative;
                    display: block;
                    outline: none;
                    user-select: none;
                    width: 0.4rem;
                    height: 0.4rem;
                    border-radius: 100%;
                    background-color: $color-black;
                    cursor: pointer;
                    margin-top: 0.8rem;
                    margin-bottom: 0.8rem;

                    &::after {
                        content: "";
                        display: block;
                        width: 0.8rem;
                        height: 0.8rem;
                        border: 0.1rem solid transparent;
                        border-radius: 100%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translateX(-50%) translateY(-50%);
                        transition: border-color $anim-duration-button ease-in-out;
                        will-change: border-color;
                    }

                    &-active {
                        &::after {
                            border-color: $color-black;
                        }
                    }
                }
            }
        }
    }

    .hero-content {
        position: relative;
        z-index: 1;
        margin-top: -6rem;
        padding-top: 3rem;
        padding-left: $container-gutter-mobile;
        padding-right: $container-gutter-mobile;

        @include for-tablet-landscape-up {
            margin-top: -12rem;
            padding-top: 8rem;
            padding-left: 0;
            padding-right: 0;
        }
        position: relative;
        text-align: center;

        &__icon {
            // display: inline-block;
            width: auto;
            height: 3rem;
            @include for-tablet-landscape-up {
                height: 4.8rem;
            }
        }

        &__title {
            @extend %heading-1;
            max-width: 72rem;
            margin: 0 auto;

            &:not(:last-child) {
                margin-bottom: 3.2rem;
            }

            &--left,
            &--right {
                .hero-content__icon {
                    display: inline-block;
                    margin: 0;
                    transform: translateY(1rem);
                }
            }

            &--left {
                .hero-content__icon {
                    margin-right: 1.6rem;
                }
            }

            &--right {
                .hero-content__icon {
                    margin-left: 1.6rem;
                }
            }

            &--top,
            &--bottom {
                .hero-content__icon {
                    display: block;
                    margin: 0 auto;
                }
            }

            &--bottom {
                .hero-content__icon {
                    margin-top: 4rem;
                }
            }

            &--top {
                .hero-content__icon {
                    margin-bottom: 4rem;
                }
            }
        }

        &__text {
            @extend %text;
            font-size: 1.8rem;
            max-width: 72rem;
            margin: 0 auto;
        }
    }

    &.block--yellow {
        .hero-content {
            background-color: $color-tertiary-1;
        }
    }

    &.block--white {
        .hero-content {
            background-color: white;
        }
    }

    &--slogan {
        .hero-gallery {
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba($color-primary-4, 0.6);
                z-index: 2;
                pointer-events: none;
            }
        }

        .hero-gallery {
            .swiper-pagination {
                &-bullet {
                    background-color: white;

                    &-active {
                        &::after {
                            border-color: white;
                        }
                    }
                }
            }
        }
    }
}
