@mixin heading {
    display: block;
    font-weight: $font-weight-medium;
    color: $color-primary-4;
}

%heading-giga {
    @include heading;
    font-size: 3rem;
    line-height: 1.4;
    @include for-tablet-landscape-up {
        font-size: 4rem;
    }
}

%heading-1 {
    @include heading;
    font-size: 2.4rem;
    line-height: 1.25;
    @include for-tablet-landscape-up {
        font-size: 3.2rem;
    }
}

%heading-2 {
    @include heading;
    font-size: 2rem;
    line-height: 1.25;
    @include for-tablet-landscape-up {
        font-size: 2.4rem;
    }
}

%heading-3 {
    @include heading;
    font-size: 1.7rem;
    line-height: 1.667;
    @include for-tablet-landscape-up {
        font-size: 1.8rem;
    }
}

%heading-4 {
    @include heading;
    font-size: 1.6rem;
    line-height: 1.667;
}

%pretitle {
    display: block;
    text-transform: uppercase;
    color: $color-light-gray;
    font-size: 1.4rem;
    font-weight: $font-weight-medium;
    line-height: 1.7rem;
    letter-spacing: 0.01em;
}

%label {
    display: block;
    font-size: 1.6rem;
    font-weight: $font-weight-semibold;
    line-height: 1.25;
    text-transform: uppercase;
    color: $color-primary-4;
}

%footer-title {
    display: block;
    font-size: 1.4rem;
    font-weight: $font-weight-regular;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: inherit;

    &:not(:last-child) {
        margin-bottom: 1.6rem;
    }
}

%text {
    h3 {
        @extend %heading-3;
        + p,
        + ol,
        + ul {
            margin-top: 0.5em;
        }
    }

    h4 {
        @extend %heading-4;

        + p,
        + ol,
        + ul {
            margin-top: 0.5em;
        }
    }

    p,
    ol,
    ul {
        margin: 1em 0;
        font-weight: $font-weight-light;

        a {
            color: $color-black;
            text-decoration-color: $color-primary-2;
            text-underline-position: under;
            font-weight: $font-weight-medium;

            &:hover {
                text-decoration-color: transparent;
            }
        }

        u {
            text-underline-position: under;
        }
    }

    ul {
        li {
            padding-left: 1.2em;
            position: relative;

            &::before {
                content: "\25AA"; // •
                position: absolute;
                left: 0;
                top: 0;
                color: $color-primary-3;
                font-size: 0.8em;
                margin-top: 0.1em;
            }
        }
    }

    ol {
        list-style-type: decimal-leading-zero;

        li {
            margin-left: 1.5em;
        }
    }

    strong {
        font-weight: $font-weight-medium;
    }

    & > *:first-child {
        margin-top: 0;
    }

    & > *:last-child {
        margin-bottom: 0;
    }

    table {
        width: 100%;
        margin-bottom: 1.5em;
        border-collapse: collapse;
        &, th, td {
            border: 1px solid $color-light-gray;
        }
        th, td {
            padding: 0.5em;
            p, ul, ol {
                margin: 0 0 0.5em;
                &:last-child {
                    margin: 0;
                }
            }
        }
    }
}
